import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import s from './SliderGallery.scss';
import {withGlobals} from '../../../globalPropsContext';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../stylesParamsContext';
import {GridType, IGallerySantaProps} from '../../../types/galleryTypes';
import classnames from 'classnames';
import type {Swiper as SwiperClass} from 'swiper/types';
import {SliderGalleryTitle} from './SliderGalleryTitle/SliderGalleryTitle';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import type {SwiperProps} from 'swiper/swiper-react';
import {ArrowsNavigationWrapper} from './ArrowsNavigationWrapper/ArrowsNavigationWrapper';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';
import {ProductItemSlide} from './ProductItemSlide/ProductItemSlide';
import {Experiments} from '../../../constants';
import {useEnvironment, useExperiments, usePanorama} from '@wix/yoshi-flow-editor';
import {ProductMediaDataHook} from '../../../common/components/ProductItem/ProductMedia/ProductMedia';
import {PaginationDots} from './PaginationDots/PaginationDots';
import {ConditionalRender} from '../../../common/components/ConditionalRender/ConditionalRender';
import {IPropsInjectedByViewerScript} from '../../../types/sliderGalleryTypes';
import {ISliderGlobalProps} from '../../sliderGlobalStrategy';
import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import {EmptySliderGallery} from './EmptySliderGallery/EmptySliderGallery';
import {useSliderConfiguration} from './hooks/useSliderConfiguration';
import {withRepeatedSlotsPlaceholders} from '@wix/widget-plugins-ooi';

export const VisibleSlideClassName = 'VisibleSlideClassName';

export enum SliderGalleryDataHook {
  Root = 'SliderGalleryDataHook.Root',
  Slide = 'SliderGalleryDataHook.Slide',
  SliderAnnouncer = 'SliderGalleryDataHook.SliderAnnouncer',
  Navigation = 'SliderGalleryDataHook.Navigation',
  PaginationDotWrapper = 'SliderGalleryDataHook.PaginationDotWrapper',
}

export type SliderGalleryProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  ISliderGlobalProps['globals']
> &
  ISliderGlobalProps;

/* eslint-disable sonarjs/cognitive-complexity */
export const SliderGallery: React.FC<SliderGalleryProps> = withRepeatedSlotsPlaceholders(
  withGlobals((props: SliderGalleryProps) => {
    const {globals, host, onAppLoaded, hideGallery, isLoaded} = props;
    const {loadPrevProductsIfNeeded, loadNextProductsIfNeeded} = globals;

    const {experiments} = useExperiments();
    const {isRTL} = useEnvironment();

    const rootRef = useRef<HTMLDivElement>(null);
    const swiperRef = useRef<HTMLDivElement>(null);
    const [swiper, setSwiper] = useState<SwiperClass>(null);
    const [swiperCurrentIndex, setSwiperCurrentIndex] = useState<number>(0);
    const [arrowsContainerHeight, setArrowsContainerHeight] = useState<number>();
    const [a11yAnnouncer, setA11yAnnouncer] = useState<Announcer>(null);
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const autoGrid = styles.get(stylesParams.gallery_gridType) === GridType.AUTO;
    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(
      /* istanbul ignore next: hard to test while we have to mock swiper */ () => updateState({}),
      []
    );

    const {totalProducts} = globals;

    const minProductWidth = styles.get(stylesParams.gallery_productSize);
    const responsiveLayoutGap = styles.get(stylesParams.gallery_gapSizeColumn);
    const swiperWidth = swiperRef?.current?.parentElement?.clientWidth;
    const {spaceBetween, slidesOffset, shouldLoop, slidesCount} = useSliderConfiguration(
      swiperRef?.current?.parentElement?.clientWidth
    );

    const shouldShowTeaser =
      styles.get(stylesParams.gallery_sliderShowTeaser) && totalProducts > slidesCount && slidesCount > 1;

    // istanbul ignore next: cant test with jsdom, tested by sled
    useEffect(() => {
      if (!swiperWidth || !slidesCount) {
        return;
      }

      setTimeout(() => swiperRef.current?.classList.remove(s.ssrFix), 0);

      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [swiperRef?.current, swiperWidth, minProductWidth, responsiveLayoutGap, slidesCount]);

    useEffect(() => {
      // istanbul ignore next: cant test with jsdom, tested by sled
      const resizeObserver = new ResizeObserver(() => updateImageHeight());

      if (rootRef.current) {
        resizeObserver?.observe(rootRef.current);
      }

      return () => {
        resizeObserver?.disconnect();
      };
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [rootRef, rootRef.current]);

    useEffect(() => {
      host.registerToComponentDidLayout(reportAppLoaded);
      setA11yAnnouncer(new Announcer(SliderGalleryDataHook.SliderAnnouncer));
      loadPrevProductsIfNeeded?.(0);

      return () => {
        a11yAnnouncer?.cleanup();
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reportAppLoaded = () => {
      if (globals.isInteractive) {
        onAppLoaded?.();
      }
    };

    const isEmptyState = (): boolean => {
      const {products, isCategoryVisible} = globals;
      return !products?.length || !isCategoryVisible;
    };

    const panorama = usePanorama();

    /* istanbul ignore next: hard to test while we have to mock swiper */
    const getKeyboardFocusableElements = (element: HTMLElement) => {
      if (!experiments.enabled(Experiments.SliderGalleryDisableThrowOnA11yInitErrors)) {
        const tagSelectors = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
        return [...element.querySelectorAll(`:is(${tagSelectors})`)].filter(
          (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
        );
      }

      try {
        const tagSelectors = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
        const focusableElements = element.querySelectorAll(`:is(${tagSelectors})`);
        return [...focusableElements].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
      } catch (e) {
        panorama.logger().error(e, {element: element.innerHTML});
      }
    };

    /* istanbul ignore next: hard to test while we have to mock swiper */
    const updateSlidesKeyboardNavigation = (_swiper: SwiperClass) => {
      _swiper.slides.forEach((slide: HTMLAnchorElement, i) => {
        const elements = getKeyboardFocusableElements(slide);
        const tabIndex = _swiper.visibleSlidesIndexes.includes(i) ? 0 : -1;
        elements.forEach((el: HTMLAnchorElement) => (el.tabIndex = tabIndex));
      });
    };

    const renderPlaceholderSlides = () => {
      const numberOfPlaceholders = autoGrid ? 40 : 6;
      return (
        <>
          {Array.from({length: numberOfPlaceholders}, (_, i) => (
            <SwiperSlide key={`slider_placeholder_${i}`} className={s.swiperSlide}>
              <div data-hook={SliderGalleryDataHook.Slide} className={s.productItemSlide}>
                <ProductPlaceholder />
              </div>
            </SwiperSlide>
          ))}
        </>
      );
    };

    const renderSlides = () => {
      const visibleSlidesIndexes = [...(swiper?.visibleSlidesIndexes || [])];
      return globals.products.map((product, i) => (
        <SwiperSlide key={i} className={s.swiperSlide} tabIndex={-1} aria-hidden={!visibleSlidesIndexes.includes(i)}>
          <ProductItemSlide
            dataHook={SliderGalleryDataHook.Slide}
            className={s.productItemSlide}
            product={product}
            index={i}
            a11yAnnouncer={a11yAnnouncer}
          />
        </SwiperSlide>
      ));
    };

    const shouldNotShowSlider = () => {
      const {isCategoryVisible, isEditorMode} = globals;
      return !isLoaded || hideGallery || (!isEditorMode && !isCategoryVisible);
    };

    // This updates swiper's snap grid object to make swiping left/right move a bit more/less which causes it to display
    // a bit of the next/prev item, which is basically the teaser
    // and makes sure the teaser has equal sizes on both sides
    /* istanbul ignore next: hard to test while we have to mock swiper, will test in sled */
    const offsetSnapGridForTeaser = useCallback(() => {
      const slideWidth: number = swiper.slidesSizesGrid?.[0];
      if (!slideWidth) {
        return null;
      }

      const fullyVisibleSlidesCount = Math.floor(slidesCount);
      const fullyVisibleSlidesSpace = (spaceBetween + slideWidth) * fullyVisibleSlidesCount;
      const leftoverSpaceForTeasers = swiper.width - fullyVisibleSlidesSpace + spaceBetween;
      const singleSideTeaserWidth = leftoverSpaceForTeasers / 2 - slidesOffset;

      swiper.snapGrid.forEach((_: number, i: number) => {
        if (i === 0) {
          return;
        }

        if (i === swiper.snapGrid.length - 1 && !shouldLoop) {
          return;
        }

        swiper.snapGrid[i] = (swiper.snapGrid[i - 1] as number) + spaceBetween + slideWidth;

        if (i === 1) {
          swiper.snapGrid[i] -= singleSideTeaserWidth;
        }
      });
    }, [shouldLoop, slidesOffset, spaceBetween, slidesCount, swiper]);

    useEffect(() => {
      if (!swiper || !shouldShowTeaser || !slidesCount) {
        return;
      }

      offsetSnapGridForTeaser();
    }, [slidesCount, shouldShowTeaser, offsetSnapGridForTeaser, swiper, swiper?.snapGrid]);

    /* istanbul ignore next: hard to test while we have to mock swiper, will test in sled */
    const updateImageHeight = () => {
      const imageElement = rootRef.current.querySelector(`[data-hook="${ProductMediaDataHook.Images}"]`);
      const imageHeight = imageElement?.clientHeight;
      if (imageHeight) {
        setArrowsContainerHeight(imageHeight);
      }
    };

    const {isCategoryVisible, isEditorMode} = globals;
    if (isEditorMode && !isCategoryVisible) {
      return <EmptySliderGallery />;
    }

    if (shouldNotShowSlider()) {
      return null;
    }

    const enoughProductsToNavigate = totalProducts > slidesCount;
    const loop = enoughProductsToNavigate && shouldLoop;
    const productsAmountPerSwipe = 1;
    const swiperConfig: SwiperProps = {
      modules: [Mousewheel],
      onSwiper: setSwiper,
      slideVisibleClass: VisibleSlideClassName,
      threshold: 2,
      updateOnWindowResize: true,
      loop,
      dir: isRTL ? 'rtl' : 'ltr',
      speed: 600,
      simulateTouch: false,
      mousewheel: {forceToAxis: true, sensitivity: 0.1, releaseOnEdges: true},
      onSlideNextTransitionStart: (_swiper) => loadNextProductsIfNeeded(_swiper.realIndex),
      onSlidePrevTransitionStart: (_swiper) => loadPrevProductsIfNeeded(_swiper.realIndex),
      onScroll: (_swiper) => setSwiperCurrentIndex(_swiper.realIndex),
      onTransitionStart: (_swiper) => setSwiperCurrentIndex(_swiper.realIndex),
      onActiveIndexChange: updateSlidesKeyboardNavigation,
      onResize: /* istanbul ignore next: hard to test while we have to mock swiper */ (_swiper) => {
        updateSlidesKeyboardNavigation(_swiper);
        forceUpdate();
      },
      slidesPerView: slidesCount ?? 'auto',
      spaceBetween,
      slidesOffsetBefore: slidesOffset,
      slidesOffsetAfter: slidesOffset,
      resizeObserver: true,
      loopAdditionalSlides: shouldShowTeaser ? 1 : 0,
    };

    const areArrowsNeeded = enoughProductsToNavigate;
    const hasPrevItems = shouldLoop || !swiper?.isBeginning;
    const hasNextItems = shouldLoop || !swiper?.isEnd;

    const inlineCssVars = {'--totalNumberOfProducts': totalProducts};

    const swiperKey = loop ? 'loop' : 'no-loop';
    const shouldAddMobileClassesToSliderGalleryRoot = experiments.enabled(
      Experiments.AddMobileClassesToSliderGalleryRoot
    );
    const allowGalleryContainerPadding = experiments.enabled(Experiments.AllowGalleryContainerPadding);

    return (
      <div
        data-hook={SliderGalleryDataHook.Root}
        className={classnames(s.root, {
          notCssPerBreakpoint: !host.usesCssPerBreakpoint && shouldAddMobileClassesToSliderGalleryRoot,
          [s.fixedPadding]: !allowGalleryContainerPadding,
          [s.dynamicPadding]: allowGalleryContainerPadding,
        })}
        ref={rootRef}>
        <div className={classnames({[s.layoutContainer]: allowGalleryContainerPadding})}>
          <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
          <SliderGalleryTitle />
          <div style={inlineCssVars as React.CSSProperties} className={classnames(s.swiperContainer)}>
            <ArrowsNavigationWrapper
              arrowsContainerHeight={arrowsContainerHeight}
              hasPrevItems={areArrowsNeeded && hasPrevItems}
              hasNextItems={areArrowsNeeded && hasNextItems}
              navigateNext={() => swiper?.slideNext()}
              navigatePrev={() => swiper?.slidePrev()}>
              <Swiper
                className={classnames(s.swiperRoot, s.ssrFix, {
                  [s.autoGrid]: autoGrid,
                })}
                key={swiperKey}
                role={'group'}
                {...swiperConfig}
                watchSlidesProgress={true}
                ref={swiperRef}>
                {isEmptyState() ? renderPlaceholderSlides() : renderSlides()}
              </Swiper>
            </ArrowsNavigationWrapper>
            <ConditionalRender by={'gallery_showSliderPaginationDots'}>
              <PaginationDots
                swiperCurrentIndex={swiperCurrentIndex}
                totalProducts={totalProducts}
                slidesCount={slidesCount}
                shouldLoop={shouldLoop}
                productsAmountPerSwipe={productsAmountPerSwipe}
              />
            </ConditionalRender>
          </div>
        </div>
      </div>
    );
  })
);
